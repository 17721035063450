import './FormLoader.scss';
import React from 'react';

const FormLoader = ({ setYellow }) => (
    <div className="form-loader">
        <h2>Ładowanie formularza...</h2>
        <div className={setYellow ? 'loader-yl loader' : 'loader'}>
            <div />
            <div />
            <div />
        </div>
    </div>
);

export default FormLoader;
