import * as React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import gsap from 'gsap';
import Footer from '../sections/Footer/Footer';
import Navbar from '../components/Navbar/Navbar';
import FormLoader from '../components/FormLoader/FormLoader';
import HeroSlideup from '../sections/HeroSlideup/HeroSlideup';
import Button from '../components/Button/Button';
import Logo from '../assets/gethero_white.svg';
import entryAnimation from '../resources/entry-animation';
import fadeInElementAnimation from '../resources/fade-in-element-animation';

const ProjectStartPage = () => {
    const data = useStaticQuery(query);
    const footer = data.allFooter.nodes[0];

    const navbarBreakpointRef = useRef();
    const [isLoaded, setIsLoaded] = useState(false);
    const [tab, setTab] = useState(1);
    const mainRef = useRef(null);
    entryAnimation(mainRef, true);
    const scriptUrl =
        'https://r5c8k9e7pe.execute-api.us-east-1.amazonaws.com' +
        '/prod-aws/flotiq-forms/project_start_registrations-nkp2fndpj';

    const handleOnLoad = () => {
        setIsLoaded(true);
    };
    const tab1 = useRef(null);
    const tab2 = useRef(null);
    const handleScriptInject = ({ scriptTags }) => {
        if (scriptTags) {
            const scriptTag = scriptTags[0];
            scriptTag.onload = handleOnLoad;
        }
    };
    const mainSectionRef = useRef(null);
    const { privacy_policy_text, privacy_policy_link } = { ...footer };

    fadeInElementAnimation([mainSectionRef]);
    return (
        <main ref={mainRef} className="project-start">
            <div ref={navbarBreakpointRef} className="ref-navbar" />

            <Navbar breakpointRef={navbarBreakpointRef} />
            <Helmet
                onChangeClientState={(newState, addedTags) =>
                    handleScriptInject(addedTags)
                }
            >
                <title>PROJECT START BY GETHERO</title>

                <link
                    rel="stylesheet"
                    href="https://flotiq-form-generator-styles.s3.amazonaws.com/styles.css"
                />
                <script src={scriptUrl} async />
            </Helmet>
            <HeroSlideup
                header={`#${data.allProjectStartPage.nodes[0].header}`}
                image={
                    data.allProjectStartPage.nodes[0].hero_image[0].localFile
                        .publicURL
                }
            />
            <div ref={mainSectionRef} className="project-start__main">
                <h3 className="project-start__header">
                    {data.allProjectStartPage.nodes[0].subheader}
                </h3>
                <div className="project-start__buttons">
                    <Button
                        click={() => {
                            const tl = gsap.timeline();
                            tl.to(tab2.current, {
                                opacity: 0,
                                duration: 0.5,
                                ease: 'ease1.easeIn',
                            });
                            tl.eventCallback('onComplete', () => {
                                const tl2 = gsap.timeline();
                                setTab(1);
                                tl2.to(tab1.current, {
                                    opacity: 1,
                                    duration: 0.5,
                                    ease: 'ease1.easeIn',
                                });
                            });
                        }}
                        additionalClasses={['btn--round']}
                    >
                        Instagram | TikTok
                    </Button>
                    <Button
                        click={() => {
                            const tl = gsap.timeline();
                            tl.to(tab1.current, {
                                opacity: 0,
                                duration: 0.5,
                                ease: 'ease1.easeIn',
                            });
                            tl.eventCallback('onComplete', () => {
                                const tl2 = gsap.timeline();
                                setTab(2);
                                tl2.to(tab2.current, {
                                    opacity: 1,
                                    duration: 0.5,
                                    ease: 'ease1.easeIn',
                                });
                            });
                        }}
                        additionalClasses={['btn--round']}
                    >
                        YouTube | Twitch | Inne
                    </Button>
                </div>
                {tab === 1 ? (
                    <div ref={tab1} className="row">
                        <div className="col-lg-6 project-start__left-col">
                            <h4 className="project-start__subheader">
                                <span className="project-start__subheader__first-part">
                                    PROJECT START
                                </span>{' '}
                                <div className="project-start__subheader__second-part">
                                    <span>by</span> <img src={Logo} alt="" />
                                </div>
                            </h4>
                            <div className="project-start__description">
                                {
                                    data.allProjectStartPage.nodes[0]
                                        .first_paragraph
                                }
                            </div>
                            <div className="project-start__description">
                                {
                                    data.allProjectStartPage.nodes[0]
                                        .second_paragraph
                                }
                            </div>
                        </div>
                        <div className="col-lg-6 project-start-registration__form">
                            <span className="required-info">*Obowiązkowe</span>
                            {isLoaded ? <flotiq-form /> : <FormLoader />}
                            <div className="d-flex justify-content-end">
                                <a
                                    href={privacy_policy_link}
                                    target="_blank"
                                    className="privacy-policy-link privacy-policy-link"
                                >
                                    {privacy_policy_text}
                                </a>
                            </div>
                            <div className="project-start-registration__files-info">
                                <h5>Ważne informacje:</h5>
                                <p>
                                    Załącz do formularza screeny wymienione
                                    poniżej:
                                </p>
                                <ol>
                                    <li>
                                        Screen demografii Twoich odbiorców na
                                        TikToku
                                    </li>
                                    <li>
                                        Screen demografii Twoich odbiorców na
                                        Instagramie
                                    </li>
                                    <li>
                                        Screen statystyk przykładowego posta na
                                        Instagramie
                                    </li>
                                    <li>
                                        Screen statystyk przykładowego Instagram
                                        Story
                                    </li>
                                </ol>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div ref={tab2} className="project-start__second-tab">
                        <p>
                            {
                                data.allProjectStartPage.nodes[0]
                                    .second_tab_first_text
                            }
                        </p>
                        <p>
                            {
                                data.allProjectStartPage.nodes[0]
                                    .second_tab_second_text
                            }
                        </p>
                        <a
                            href={`mailto:${data.allGetHeroMainSettings.nodes[0].email}`}
                        >
                            {data.allGetHeroMainSettings.nodes[0].email}
                        </a>
                    </div>
                )}
            </div>
            <Footer />
        </main>
    );
};

const query = graphql`
    query ProjectStartSettings {
        allProjectStartPage {
            nodes {
                header
                hero_image {
                    localFile {
                        publicURL
                    }
                }
                first_paragraph
                second_paragraph
                second_tab_first_text
                second_tab_second_text
                subheader
            }
        }
        allGetHeroMainSettings {
            nodes {
                email
            }
        }
        allFooter {
            nodes {
                privacy_policy_text
                privacy_policy_link
            }
        }
    }
`;

export default ProjectStartPage;
