import React from 'react';
import './Button.scss';

function Button({ additionalClasses, children, click }) {
    return (
        <button className={['btn', additionalClasses].join(' ')} onClick={click}>
            {children}
        </button>
    );
}

export default Button;
